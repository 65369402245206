import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import classNames from 'classnames';
import Layout from 'components/Layout';
import Banner from 'components/common/Banner';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import PromoFormComponent from '@promo-platform/promo-form';
import { getUtmParameters } from '@promo-platform/utils/';

import '@promo-platform/styles';
import 'styles/main.scss';
import './SignUpPage.scss';
import useScript from 'hooks/useScript';
import { Col } from 'react-bootstrap';

type TProps = {
  data: ISignUpPageData;
};

interface ISignUpPageData extends PageContent.IDefaultLayoutProps {
  signUp: SignUpTypes.ISignUp;
}

const SignUpPage: FC<TProps> = ({ data }) => {
  const {
    signUp,
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  } = data;
  const {
    seoNoIndex,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoCanonicalUrl,
    seoExternalHreflangs,
    seoImage,
    ogPageType,
    image,
    imageAlt,
    title,
    description,
    submenu,
    promoUrl,
    promoId,
    thankyouTitle,
    thankyouDescription,
    link,
    consent,
    customClass,
  } = signUp;
  const scriptAdress = promoUrl ? `${new URL(promoUrl).origin}/promoForm.js` : '';
  useScript(scriptAdress);

  const customClassForm = classNames('promo-block', {
    [`promo-block--${customClass}`]: customClass,
  });

  return (
    <Layout
      seo={{
        seoNoIndex,
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
        ogPageType,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      <section className="dt-sign-up">
        <Banner
          title={{ boldText: title }}
          text={description}
          textWrapElement="div"
          img={image}
          alt={imageAlt}
          large
        />
        <Container fluid>
          <Row>
            {thankyouTitle ? (
              <Col sm={12} className="dt-sign-up__title-section">
                <h2 className="dt-sign-up__thankyou-title">{thankyouTitle}</h2>
                <DangerouslySetInnerHtml
                  className="dt-sign-up__thankyou-description"
                  html={thankyouDescription}
                />
              </Col>
            ) : (
              <Col sm={12} md={{ span: 6, offset: 2 }} className="dt-sign-up__title-section">
                <h2 className="dt-sign-up__title">{submenu}</h2>
              </Col>
            )}
            {promoId && promoUrl ? (
              <div className={customClassForm}>
                <PromoFormComponent
                  id={promoId}
                  url={promoUrl}
                  utmParameters={getUtmParameters()}
                />
                <DangerouslySetInnerHtml className="promo-block__description" html={consent} />
              </div>
            ) : null}
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $link: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    signUp(lang: { eq: $lang }, link: { eq: $link }) {
      title
      link
      description
      promoId
      promoUrl
      submenu
      thankyouTitle
      thankyouDescription
      consent
      customClass
      image {
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
      seoNoIndex
      seoMetaKeywords
      seoMetaDescription
      seoMetaTitle
      seoCanonicalUrl
      seoImage
      ogPageType
    }
  }
`;

export default SignUpPage;
